.App .add-game-container {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.add-game-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
    width: 100%;
}

.add-game-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
}

.input-field {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
    box-sizing: border-box;
    text-align: center;
}

.button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

.button:hover {
    background-color: #0056b3;
}

.button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.error-message, .warning-text, .success-text, .group-verification-text {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
}

.error-message {
    color: red;
}

.warning-text {
    color: #FFA500;
}

.success-text {
    color: #4CAF50;
}

.group-verification-text {
    color: #2196F3;
}

.divider {
    height: 1px;
    background-color: #ccc;
    margin: 1rem 0;
    width: 100%;
}

.dashboard-link {
    color: #007bff;
    text-decoration: none;
    margin-top: 1rem;
}

.dashboard-link:hover {
    text-decoration: underline;
}
