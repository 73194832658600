.signup-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
}

.input-field {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #0056b3;
}

.button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-bottom: 1rem;
}

.divider {
  height: 1px;
  background-color: #ccc;
  margin: 1rem 0;
}

.copy-text {
  margin-bottom: 0.5rem;
}

.roblox-link {
  display: block;
  margin-top: 0.5rem;
  color: #007bff;
  text-decoration: none;
}

.roblox-link:hover {
  text-decoration: underline;
}

.role-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.role-selection .button {
  width: 45%;
}

.or-text {
  font-weight: bold;
}

.button.active {
  background-color: #28a745;
}
