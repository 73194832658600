.sell-product-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.product-upload-header {
  text-align: center;
  margin-bottom: 30px;
}

.button-group.vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
}

.button-group.vertical .create-button,
.button-group.vertical .import-button {
  width: 100%;
  margin: 10px 0;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.or-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px 0;
}

.or-divider {
  flex-grow: 1;
  height: 1px;
  background-color: #ddd;
}

.or-text {
  color: #ddd;
  margin: 0 10px;
  padding: 0 10px;
  background-color: white;
}

form {
  display: flex;
  flex-direction: column;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

label {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
  text-align: left;
}

input, select, textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
}

button[type="submit"] {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: #4CAF50;
  margin-top: 10px;
}

.dashboard-link {
  display: block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

.dashboard-link:hover {
  text-decoration: underline;
}
