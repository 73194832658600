.dashboard-container, .games-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto 10px;
  padding: 0;
  box-sizing: border-box;
}

.button-group {
  display: flex;
  gap: 10px;
}

.add-game-button, .logout-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.add-game-button {
  background-color: #007bff;
  color: white;
}

.logout-button {
  background-color: #dc3545;
  color: white;
}

.logout-button:hover {
  background-color: #bd2130;
}

.games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.game-card {
  background-color: #FFFFFF;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.game-thumbnail {
  width: 160px !important; /* Force width */
  height: 90px !important; /* Force height for 16:9 aspect ratio */
  object-fit: cover !important;
  margin-right: 20px;
  flex-shrink: 0;
  display: block; /* Ensure it's treated as a block element */
  max-width: none; /* Prevent max-width constraints */
  max-height: none; /* Prevent max-height constraints */
  background-color: #f0f0f0; /* Light grey background */
}

.game-card h3 {
  margin: 0.5rem 0;
}

.game-card p {
  font-size: 0.9rem;
  color: #6c757d;
  display: -webkit-box;
  display: flex;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
}

.no-games {
  text-align: center;
  margin-top: 20px;
  font-style: italic;
  color: #666;
}

.games-dropdown, .products-dropdown {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  background-color: #FFFFFF;
}

.games-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #FFFFFF; /* Changed to white */
  cursor: pointer;
  border-bottom: 1px solid #ddd; /* Added border to separate header from list */
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.games-list {
  background-color: #FFFFFF; /* This was likely already white, but explicitly set for clarity */
}

.game-row {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 4px;
}

.game-thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 20px;
}

.game-info {
  flex: 1;
  min-width: 0; /* Allow flex item to shrink below its minimum content size */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; /* Align content to the left */
  overflow: hidden; /* Ensure text doesn't overflow */
}

.game-info h4 {
  margin: 0 0 5px 0;
  text-align: left; /* Ensure left alignment */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.game-info p {
  margin: 0;
  font-size: 0.9rem;
  color: #6c757d;
  text-align: left; /* Ensure left alignment */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.edit-button {
  opacity: 0;
  position: absolute;
  right: 20px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.game-row:hover .edit-button {
  opacity: 1;
}

.button-group {
  display: flex;
  gap: 10px;
}

.refresh-button, .sell-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: opacity 0.3s ease;
}

.refresh-button {
  background-color: #4CAF50;
  color: white;
}

.refresh-button:hover {
  background-color: #45a049;
}

.sell-button {
  background-color: #f44336;
  color: white;
}

.game-row:hover .refresh-button,
.game-row:hover .sell-button {
  opacity: 1;
}

/* You might want to adjust this if you want the buttons always visible */
.refresh-button, .sell-button {
  opacity: 0;
}

.game-row:last-child {
  margin-bottom: 0;
}

.view-products-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: opacity 0.3s ease;
  background-color: #007bff;
  color: white;
}

.game-row:hover .view-products-button {
  opacity: 1;
}

.view-products-button {
  opacity: 0;
}

.sell-products-button {
  background-color: #28a745;
  color: white;
}

.sell-products-button:hover {
  background-color: #218838;
}

.products-dropdown {
  margin-top: 20px;
}

.products-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #FFFFFF;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.products-list {
  background-color: #FFFFFF;
}

.product-row {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 4px;
  position: relative;
}

.product-row:last-child {
  margin-bottom: 0;
}

.product-thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 20px;
}

.product-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.product-info h4 {
  margin: 0 0 5px 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.product-info p {
  margin: 0;
  font-size: 0.9rem;
  color: #6c757d;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.product-row .edit-button {
  opacity: 0;
  position: absolute;
  right: 20px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.product-row:hover .edit-button {
  opacity: 1;
}

.no-products {
  padding: 20px;
  text-align: center;
  color: #6c757d;
}

.edit-product-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 10px;
}

.edit-product-form input,
.edit-product-form textarea {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.edit-product-form .button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.delete-button {
  opacity: 0;
  position: absolute;
  right: 80px;
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.product-row:hover .delete-button {
  opacity: 1;
}

.delete-confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.delete-confirmation-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
}

.delete-confirmation-content .button-group {
  margin-top: 20px;
}

.delete-confirmation-content button {
  margin: 0 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-confirmation-content button:first-child {
  background-color: #dc3545;
  color: white;
}

.delete-confirmation-content button:last-child {
  background-color: #6c757d;
  color: white;
}

.product-info h4 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.product-info .game-name {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
}